import React from "react";
import styles from "./index.module.css";
import Prometeo from "../../../assets/prometeo-logo.svg";

interface IProps {}

const SponsoredByView: React.FC<IProps> = () => {
  return (
    <p className={styles.container}>
      Asegurado por <img src={Prometeo} alt="" />{" "}
    </p>
  );
};

export default SponsoredByView;
