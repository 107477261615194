import React from "react";
import { Button } from "antd";
import BannerMaximo from "../../../assets/banner-maximo.svg";
import commonStyles from "../../common/common.module.css";
import styles from "./index.module.css";
import { HttpMethod, useFetch } from "../../../hooks/useFetch";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { ITransaction, IWidgetInfo } from "../../../types";
import { OperationType } from "../../../constants";
import LoaderView from "../loader-view";
import classNames from "classnames";

interface IProps {
  widgetInfo: IWidgetInfo;
  onSuccess: (transaction: ITransaction) => void;
  onError: (message: string) => void;
}

const TransactionPreviewView: React.FC<IProps> = ({
  widgetInfo,
  onSuccess,
  onError,
}) => {
  const { customFetch } = useFetch();
  const { currentUser } = useCurrentUser();
  const [wasFormSubmitted, setWasFormSubmitted] = React.useState(false);
  const [previewInfo, setPreviewInfo] = React.useState<any>();
  const todayDate = new Date().toLocaleDateString("pt-PT");

  const handleContinue = async () => {
    setWasFormSubmitted(true);
    try {
      const response = await customFetch(
        `users/${currentUser?.id}/transaction`,
        HttpMethod.Post,
        {
          widgetId: widgetInfo.widgetId,
        }
      );
      if (response.status === "SUCCESS") {
        onSuccess(response.transaction);
      } else {
        onError(response.message);
      }
    } catch (error) {
      console.log(error);
      onError(error as string);
    }
  };

  const handleFetchPreviewTransaction = async () => {
    try {
      const response = await customFetch(
        `widget/${widgetInfo.widgetId}/preview`,
        HttpMethod.Post,
        {
          user: currentUser?.id,
          method: "MAXIMO",
        }
      );
      if (response.status === "SUCCESS") {
        setPreviewInfo(response.preview);
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    handleFetchPreviewTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPayIn = widgetInfo.type === OperationType.PayIn;

  return (
    <section className={commonStyles.container}>
      {!previewInfo ? (
        <LoaderView />
      ) : (
        <>
          <div style={{ marginBottom: "50px" }}>
            <img src={BannerMaximo} alt="logo-maximo" />
          </div>
          <div className={styles.table}>
            <div className={styles.row}>
              <p>Detalles {isPayIn ? "de la recarga" : "del retiro"}</p>
            </div>
            <div className={styles.row}>
              <p>Monto a {isPayIn ? "recargar" : "retirar"}:</p>
              <p>S/{previewInfo?.originalAmount}</p>
            </div>
            <div className={styles.row}>
              <p>Costo de uso de plataforma:</p>
              <p>S/{previewInfo?.commision}</p>
            </div>
            <div className={styles.row}>
              <p>Medio de {isPayIn ? "recarga" : "retiro"}:</p>
              <p>{previewInfo?.method}</p>
            </div>
            <div className={styles.row}>
              <p>Fecha de {isPayIn ? "recarga" : "retiro"}:</p>
              <p>{todayDate}</p>
            </div>
            <div className={classNames(styles.row, styles.rowBold)}>
              <p>Monto Total a {isPayIn ? "Recargar" : "Retirar"}:</p>
              <p>S/{previewInfo.amount}</p>
            </div>
          </div>
          <div>
            <Button
              className={commonStyles.button}
              onClick={handleContinue}
              loading={wasFormSubmitted}
            >
              {isPayIn ? "Realizar recarga" : "Realizar retiro"}
            </Button>
          </div>
        </>
      )}
    </section>
  );
};

export default TransactionPreviewView;
