import { Button } from "antd";
import commonStyles from "../../common/common.module.css";
import styles from "./index.module.css";
import { HttpMethod, useFetch } from "../../../hooks/useFetch";
import { IBankTransactionRequestBody } from "../../../types";
import React from "react";
import LoaderView from "../../common/loader-view";
import classNames from "classnames";
import SponsoredByView from "../../common/sponsored-by-view";
import { useFetchTransactionPreview } from "./useFetchTransactionPreview";

interface IProps {
  widgetId: string;
  bankTransactionRequest: IBankTransactionRequestBody;
  onSuccess: (transaction: any) => void;
  onError: (message: string) => void;
}

const BankTransactionPreviewView: React.FC<IProps> = ({
  onSuccess,
  onError,
  widgetId,
  bankTransactionRequest,
}) => {
  const { customFetch } = useFetch();
  const [wasFormSubmitted, setWasFormSubmitted] = React.useState(false);
  const { onFetchTransactionPreview, previewInfo, isFetching, error } =
    useFetchTransactionPreview();

  React.useEffect(() => {
    onFetchTransactionPreview({ ...bankTransactionRequest, widgetId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (error) {
      onError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleContinue = async () => {
    try {
      setWasFormSubmitted(true);
      const response = await customFetch(
        `widget/${widgetId}/withdrawal`,
        HttpMethod.Post,
        {
          accountNumber: bankTransactionRequest.accountNumber,
          cci: bankTransactionRequest.cci,
          bank: bankTransactionRequest.bank,
        }
      );
      if (response.status === "SUCCESS") {
        onSuccess(response.transaction);
      } else {
        onError(response.message);
      }
    } catch (error) {
      console.log(error);
      onError(error as string);
    }
  };

  if (error) {
    return null;
  }

  return (
    <section className={commonStyles.container}>
      {isFetching ? (
        <LoaderView />
      ) : (
        <>
          <div style={{ marginBottom: "30px" }}>
            <img
              src={bankTransactionRequest.bankLogo}
              width={150}
              alt="logo-bank-transfer"
            />
          </div>
          <div className={styles.detailRecharge}>
            <div className={styles.detailTable}>
              <p>Detalles del retiro</p>
            </div>
            <div className={styles.detailTable}>
              <p>Medio de destino:</p>
              <p>{previewInfo.method}</p>
            </div>
            <div className={styles.detailTable}>
              <p>Titular de la cuenta:</p>
              <p>{previewInfo.owner}</p>
            </div>
            <div className={styles.detailTable}>
              <p>Doc. Titular de la cuenta:</p>
              <p>{previewInfo.documentNumber}</p>
            </div>
            <div className={styles.detailTable}>
              <p>Monto a retirar:</p>
              <p>S/{previewInfo.amount}</p>
            </div>
            <div className={styles.detailTable}>
              <p>Costo de uso de plataforma:</p>
              <p>S/{previewInfo.commision}</p>
            </div>
            <div
              className={classNames(styles.detailTable, styles.detailTableBold)}
            >
              <p>Monto Total a retirar:</p>
              <p>S/{previewInfo.originalAmount}</p>
            </div>
          </div>
          <div>
            <Button
              className={commonStyles.button}
              onClick={handleContinue}
              loading={wasFormSubmitted}
            >
              Realizar retiro
            </Button>
          </div>
          <SponsoredByView />
        </>
      )}
    </section>
  );
};

export default BankTransactionPreviewView;
