import React from "react";
import { Button, Form, Input, Select } from "antd";
import styles from "./index.module.css";
import commonStyles from "../../common/common.module.css";
import Hand from "../../../assets/hand.svg";
import ArrowLeft from "../../../assets/arrow-left.svg";
import SponsoredByView from "../../common/sponsored-by-view";
import { useFetchBanks } from "./useFetchBanks";
import { IBankTransactionRequestBody } from "../../../types";

interface IProps {
  onContinue: (bankTransactionRequest: IBankTransactionRequestBody) => void;
  onBack: () => void;
}

const BankTransactionInitView: React.FC<IProps> = ({ onContinue, onBack }) => {
  const [cci, setCci] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [selectedBankOption, setSelectedBankOption] = React.useState("");
  const { banks, isFetching: isFetchingBanks, onFetchBanks } = useFetchBanks();

  React.useEffect(() => {
    onFetchBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinue = async () => {
    onContinue({
      cci: cci,
      accountNumber: accountNumber,
      bank: selectedBankOption,
      bankLogo: banks.find((bank) => bank.code === selectedBankOption)?.logo,
    });
  };

  const handleChangeCci = (e: any) => {
    setCci(e.target.value);
  };

  const handleChangeAccountNumber = (e: any) => {
    setAccountNumber(e.target.value);
  };

  const bankOptions = banks.map(
    ({ code, name }: { code: string; name: string }) => ({
      label: name,
      value: code,
    })
  );

  const isFormValid =
    cci.length > 0 && accountNumber.length > 0 && selectedBankOption.length > 0;
  return (
    <section className={commonStyles.container}>
      <p className={styles.arrow} onClick={onBack}>
        <img src={ArrowLeft} alt="" /> Volver
      </p>
      <Form layout="vertical">
        <Form.Item>
          <label className={commonStyles.labelForm}>Monto a transferir:</label>
          <Input className={styles.input} disabled value={"S/500.00"} />
        </Form.Item>
        <Form.Item>
          <label className={commonStyles.labelForm}>Selecciona tu banco:</label>
          <Select
            onChange={setSelectedBankOption}
            placeholder="Seleccionar"
            options={bankOptions}
            loading={isFetchingBanks}
          />
        </Form.Item>
        <Form.Item>
          <label className={commonStyles.labelForm}>
            Número de cuenta interbancario (CCI):
          </label>
          <Input
            className={styles.input}
            placeholder="Número CCI (20 dígitos)"
            onChange={handleChangeCci}
          />
        </Form.Item>
        <Form.Item>
          <label className={commonStyles.labelForm}>Número de cuenta:</label>
          <Input
            className={styles.input}
            placeholder="Número de cuenta"
            onChange={handleChangeAccountNumber}
          />
        </Form.Item>
        <p className={styles.fotterText}>
          <img src={Hand} alt="" style={{ marginRight: "20px" }} />
          Las transferencias se realizan en 48 horas hábiles y el titular de la
          cuenta debe ser el mismo de la cuenta en BINACE
        </p>
        <div>
          <Button
            className={commonStyles.button}
            onClick={handleContinue}
            disabled={!isFormValid}
          >
            Continuar
          </Button>
        </div>
      </Form>
      <SponsoredByView />
    </section>
  );
};

export default BankTransactionInitView;
