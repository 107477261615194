import React from "react";
import styles from "./index.module.css";

interface IProps {}

const LoaderView: React.FC<IProps> = () => {
  return (
    <img
      className={styles.loader}
      src="https://xpay.maximo.pe/images/logo.svg"
    />
  );
};

export default LoaderView;
