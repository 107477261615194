import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import styles from "./index.module.css";
import commonStyles from "../../common/common.module.css";
import BannerMaximo from "../../../assets/banner-maximo.svg";
import { useFetch } from "../../../hooks/useFetch";
import { IWidgetInfo } from "../../../types";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import LoaderView from "../../common/loader-view";

interface IProps {
  widgetInfo: IWidgetInfo;
  onSuccess: () => void;
}

const WalletTransactionBalanceView: React.FC<IProps> = ({
  onSuccess,
  widgetInfo,
}) => {
  const { currentUser } = useCurrentUser();
  const [balance, setBalance] = useState<any>();
  const handleContinue = () => {
    onSuccess();
  };
  const { customFetch } = useFetch();
  const handleFetchUserBalance = async () => {
    try {
      const response = await customFetch(
        `users/${currentUser?.id}/balances?currency=PEN`
      );
      if (response.status === "SUCCESS") {
        setBalance(response.balance);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleFetchUserBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section className={commonStyles.container}>
      {!balance ? (
        <LoaderView />
      ) : (
        <>
          <div style={{ marginBottom: "20px" }}>
            <img src={BannerMaximo} alt="logo-maximo" />
          </div>
          <Form layout="vertical" style={{ width: "100%" }}>
            <Form.Item name="username">
              <label className={commonStyles.labelForm}>
                Monto a recargar:
              </label>
              <Input
                className={styles.inputFormAmount}
                disabled
                value={widgetInfo.amount}
              />
            </Form.Item>
            <Form.Item name="password">
              <label className={commonStyles.labelForm}>
                Saldo disponible en tu tarjeta:
              </label>
              <Input
                disabled
                className={styles.inputFormCard}
                value={`Tarjeta Máximo  S/ ${balance}`}
              />
            </Form.Item>
            <div>
              <Button className={commonStyles.button} onClick={handleContinue}>
                Continuar
              </Button>
            </div>
          </Form>
        </>
      )}
    </section>
  );
};

export default WalletTransactionBalanceView;
