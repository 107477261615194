import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import commonStyles from "../../common/common.module.css";
import BannerMaximo from "../../../assets/banner-maximo.svg";
import { useLogin } from "./hooks/useLogin";
import { message } from "antd";
import { IUser } from "../../../types";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

interface IProps {
  onSuccess: () => void;
}

const WalletTransactionInitView: React.FC<IProps> = ({ onSuccess }) => {
  const { onSetCurrentUser } = useCurrentUser();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const { onLogin, loading } = useLogin({
    onSuccess: (user: IUser) => {
      onSuccess();
      onSetCurrentUser(user);
    },
    onError: () => {
      message.error(
        "Se produjo un error al iniciar sesión. Contactar con el administrador."
      );
    },
  });
  const handleSubmit = () => {
    onLogin({
      username: email,
      password,
    });
  };
  const handleChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };
  const handleChangePassword = (event: any) => {
    setPassword(event.target.value);
  };

  return (
    <section className={commonStyles.container}>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ margin: '0 auto' }}>
          <img src={BannerMaximo} alt="logo-maximo" />
        </div>
      </div>
      <p className={styles.titleLogin}>
        Ingresa a tu cuenta Máximo para poder verificarte.
      </p>
      <Form layout="vertical" onFinish={handleSubmit} style={{ width: "100%" }}>
        <Form.Item name="email">
          <label className={commonStyles.labelForm}>Correo electrónico</label>
          <Input
            name="email"
            className={styles.inputForm}
            placeholder="Ingrese su correo electrónico"
            onChange={handleChangeEmail}
            value={email}
            prefix={<MailOutlined className={styles.icon} />}
          />
        </Form.Item>
        <Form.Item name="password">
          <label className={commonStyles.labelForm}>Contraseña</label>
          <Input.Password
            value={password}
            onChange={handleChangePassword}
            name="password"
            className={styles.inputForm}
            placeholder="Ingrese su contraseña"
            prefix={<LockOutlined className={styles.icon} />}
          />
        </Form.Item>
        <div>
          <Button
            disabled={!password}
            className={commonStyles.button}
            loading={loading}
            htmlType="submit"
          >
            Continuar
          </Button>
        </div>
      </Form>
    </section>
  );
};

export default WalletTransactionInitView;
