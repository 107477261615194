import React from "react";
import WalletTransactionBalanceView from "./wallet-transaction-balance-view";
import TransactionChooseMethodView from "../common/transaction-choose-method-view";
import WalletTransactionInitView from "../common/wallet-transaction-init-view";
import TransactionPreviewView from "../common/transaction-preview-view";
import TransactionSuccessView from "../common/transaction-success-view";
import { IWidgetInfo } from "../../types";
import { HttpMethod, useFetch } from "../../hooks/useFetch";
import { TransactionMethod } from "../../constants";
import { GLOBAL_VALUES } from "../../App";

enum PayInStep {
  Init,
  WalletTransactionLogin,
  WalletTransactionBalance,
  WalletTransactionPreview,
  WalletTransactionSuccess,
}

interface IProps {
  widgetInfo: IWidgetInfo;
}

const PayIn: React.FC<IProps> = ({ widgetInfo }) => {
  const { customFetch } = useFetch();
  const [step, setStep] = React.useState<PayInStep>(PayInStep.Init);
  const [transaction, setTransaction] = React.useState<any>();

  const handleSetStep = (step: PayInStep) => () => {
    setStep(step);
  };

  const handleGenerateIntentId = async () => {
    try {
      const { prometeoWidget } = await customFetch(
        `widget/${widgetInfo.widgetId}/intent`,
        HttpMethod.Post,
        {
          amount: widgetInfo.amount,
          currency: "PEN",
          concept: widgetInfo.concept,
        }
      );

      // Open Pomelo widget using POMELO SDK
      (window as any).parent.postMessage(
        {
          type: "START_PROMETEO",
          payload: {
            prometeoWidgetId: prometeoWidget.prometeoWidgetId,
            prometeoIntentId: prometeoWidget.intentId,
            maximoWidgetId: widgetInfo.widgetId,
            apiKey: GLOBAL_VALUES.apiKey,
          },
        },
        "*"
      );
    } catch (error) {
      return console.log(error);
    }
  };

  const handleSuccess = (type: TransactionMethod) => {
    if (type === TransactionMethod.BankTransfer) {
      handleGenerateIntentId();
    } else {
      setStep(PayInStep.WalletTransactionLogin);
    }
  };

  return (
    <>
      {step === PayInStep.Init && (
        <TransactionChooseMethodView
          onSuccess={handleSuccess}
          title="¿Cuánto deseas recargar?"
          subtitle="Total a Recargar:"
          isPayIn={true}
          widgetInfo={widgetInfo}
        />
      )}
      {step === PayInStep.WalletTransactionLogin && (
        <WalletTransactionInitView
          onSuccess={handleSetStep(PayInStep.WalletTransactionBalance)}
        />
      )}
      {step === PayInStep.WalletTransactionBalance && (
        <WalletTransactionBalanceView
          onSuccess={handleSetStep(PayInStep.WalletTransactionPreview)}
          widgetInfo={widgetInfo}
        />
      )}
      {step === PayInStep.WalletTransactionPreview && (
        <TransactionPreviewView
          onSuccess={(newTransaction) => {
            setTransaction(newTransaction);
            setStep(PayInStep.WalletTransactionSuccess);
          }}
          onError={(error) => {
            alert(error);
          }}
          widgetInfo={widgetInfo}
        />
      )}
      {step === PayInStep.WalletTransactionSuccess && (
        <TransactionSuccessView
          onFinish={handleSetStep(PayInStep.Init)}
          title="¡Tu recarga se realizó con éxito!"
          titleTable="Monto recargado:"
          transaction={transaction}
        />
      )}
    </>
  );
};

export default PayIn;
