import Bcp from "../../assets/bcp-logo.svg";
import Interbank from "../../assets/interbank-logo.svg";
import { Button } from "antd";
import common from "../common/common.module.css";
import GroupLogo from "../../assets/group-maximo.svg";
import classNames from "classnames";
import commonStyles from "../common/common.module.css";

interface IProps {
  text: string;
  isWalletMaximo: boolean;
  onClick: () => void;
  isActive: boolean;
}

const TransactionMethodView: React.FC<IProps> = ({
  text,
  isWalletMaximo,
  onClick,
  isActive,
}) => {
  return (
    <>
      <Button
        className={classNames(common.banks, {
          [commonStyles.shadowButton]: isActive,
        })}
        onClick={onClick}
      >
        <div className={common.containerTrasaction}>
          <div className={common.typeRecharge}>
            <div className={common.typeRechargeHeader}>
              <span>{text}</span>
              <div className={common.line}></div>
            </div>
            {!isWalletMaximo ? (
              true && (
                <div className={common.logos}>
                  <img src={Bcp} alt="logo-bcp" />
                  <img src={Interbank} alt="logo-interbank" style={{
                    marginRight: "6px",
                    marginLeft: "6px",
                  }} />
                </div>
              )
            ) : (
              <img src={GroupLogo} alt="logo-maximo" className={common.logoMaximo} />
            )}
          </div>
        </div>
      </Button >
    </>
  );
};

export default TransactionMethodView;
