import React, { useState } from "react";
import { CurrentUserContext } from "./context";
import { IUser } from "../../types";

interface IProps {
  children: React.ReactNode;
}

export const CurrentUserContextProvider: React.FC<IProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<IUser>();

  const handleSetCurrentUser = (user: IUser) => {
    setCurrentUser(user);
  };

  const contextValue = {
    onSetCurrentUser: handleSetCurrentUser,
    currentUser,
  };

  return (
    <CurrentUserContext.Provider value={contextValue}>
      {children}
    </CurrentUserContext.Provider>
  );
};
