import { createContext } from "react";
import { IUser } from "../../types";

export type ContextValues = {
  onSetCurrentUser: (user: IUser) => void;
  currentUser?: IUser;
};

export const CurrentUserContext = createContext<ContextValues>({
  onSetCurrentUser: () => {},
});
