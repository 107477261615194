import { useState } from "react";
import { IBank } from "../../../types";
import { useFetch } from "../../../hooks/useFetch";

export const useFetchBanks = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [banks, setBanks] = useState<IBank[]>([]);
  const { customFetch } = useFetch();

  const handleFetchBanks = async () => {
    try {
      setIsFetching(true);
      const response = await customFetch("banks");
      if (response.status === "SUCCESS") {
        setBanks(response.banks);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsFetching(false);
    }
  };
  return {
    isFetching,
    banks,
    onFetchBanks: handleFetchBanks,
  };
};
