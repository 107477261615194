import { Button } from "antd";
import BannerMaximo from "../../../assets/banner-maximo.svg";
import commonStyles from "../../common/common.module.css";
import styles from "./index.module.css";
import Success from "../../../assets/success-icon.svg";
import { ITransaction } from "../../../types";

interface IProps {
  onFinish: () => void;
  title: string;
  titleTable: string;
  transaction: ITransaction;
}

const TransactionSuccessView: React.FC<IProps> = ({
  onFinish,
  title,
  titleTable,
  transaction,
}) => {
  const handleFinish = () => {
    onFinish();
  };
  const transactionDate = new Date(transaction?.updated_at);
  const parsedDate = transactionDate.toLocaleDateString("pt-PT");

  return (
    <section className={commonStyles.container}>
      <div style={{ marginBottom: "20px" }}>
        <img src={BannerMaximo} alt="logo-maximo" />
      </div>
      <p className={styles.titleSuccess}>
        <img src={Success} alt="" style={{ marginRight: "10px" }} />
        {title}
      </p>
      <div className={styles.successRecharge}>
        <div className={styles.detailTable}>
          <p>{titleTable}</p>
          <p>S/{transaction?.amount}</p>
        </div>
        <div className={styles.detailTable}>
          <p>Costo de uso de plataforma:</p>
          <p>S/{transaction.commission_amount}</p>
        </div>
        <div className={styles.detailTable}>
          <p>Usuario:</p>
          <p>{transaction.client_name}</p>
        </div>
        <div className={styles.detailTable}>
          <p>ID Transacción:</p>
          <p>#{transaction.external_transaction_id}</p>
        </div>
        <div className={styles.detailTable}>
          <p>Fecha:</p>
          <p>{parsedDate}</p>
        </div>
        <div className={styles.detailTable}>
          <p>Hora:</p>
          <p>
            {transactionDate?.getHours()}:{transactionDate?.getMinutes()} hrs
          </p>
        </div>
      </div>
      <div>
        <Button className={commonStyles.button} onClick={handleFinish}>
          Cerrar
        </Button>
      </div>
    </section>
  );
};

export default TransactionSuccessView;
