import { GLOBAL_VALUES } from "../App";

export enum HttpMethod {
  Get = "GET",
  Post = "POST",
}

export function useFetch() {
  const handleFetch = async (
    url: string,
    method: HttpMethod = HttpMethod.Get,
    body?: any
  ) => {
    const baseURL = "https://prod-api-xpay.maximo.pe/";
    let config;

    if (method === HttpMethod.Post) {
      config = {
        method,
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Api-Key": GLOBAL_VALUES.apiKey,
        },
      };
    }
    const response = await fetch(`${baseURL}${url}`, config);
    return response.json();
  };

  return {
    customFetch: handleFetch,
  };
}
