import { Button } from "antd";
import BannerMaximo from "../../../assets/banner-maximo.svg";
import commonStyles from "../../common/common.module.css";
import styles from "./index.module.css";
import Error from "../../../assets/error-icon.svg";
import SponsoredByView from "../sponsored-by-view";

interface IProps {
  textError: string;
  onFinish: () => void;
}

const TransactionErrorView: React.FC<IProps> = ({ textError, onFinish }) => {
  return (
    <section className={commonStyles.container}>
      <div style={{ marginBottom: "20px" }}>
        <img src={BannerMaximo} width={250} alt="logo-maximo" />
      </div>
      <div className={styles.titleError}>
        <img src={Error} alt="" style={{ marginRight: "10px" }} />
        <p className={styles.textError}>{textError}</p>
      </div>
      <div>
        <Button className={commonStyles.button} onClick={onFinish}>
          Cerrar
        </Button>
      </div>
      <SponsoredByView />
    </section>
  );
};

export default TransactionErrorView;
