import React from "react";
import TransactionChooseMethodView from "../common/transaction-choose-method-view";
import WalletTransactionInitView from "../common/wallet-transaction-init-view";
import TransactionSuccessView from "../common/transaction-success-view";
import { IBankTransactionRequestBody, IWidgetInfo } from "../../types";
import BankTransactionInitView from "./bank-transaction-init-view";
import TransactionPreviewView from "../common/transaction-preview-view";
import TransactionErrorView from "../common/transaction-error-view";
import BankTransactionPreviewView from "./bank-transaction-preview-view";
import { TransactionMethod } from "../../constants";

enum PayOutStep {
  Init,
  WalletTransactionInit,
  WalletTransactionPreview,
  WalletTransactionSuccess,
  WalletTransactionError,
  BankTransactionInit,
  BankTransactionPreview,
  BankTransactionError,
  BankTransactionSuccess,
}

interface IProps {
  widgetInfo: IWidgetInfo;
}

const PayOut: React.FC<IProps> = ({ widgetInfo }) => {
  const [step, setStep] = React.useState<PayOutStep>(PayOutStep.Init);
  const [transaction, setTransaction] = React.useState<any>();
  const [messageError, setMessageError] = React.useState("");
  const [bankTransactionRequest, setBankTransactionRequest] =
    React.useState<IBankTransactionRequestBody>();

  const handleSetStep = (step: PayOutStep) => () => {
    setStep(step);
  };

  const handleSuccess = (type: TransactionMethod) => {
    if (type === TransactionMethod.BankTransfer) {
      setStep(PayOutStep.BankTransactionInit);
    } else {
      setStep(PayOutStep.WalletTransactionInit);
    }
  };

  return (
    <>
      {step === PayOutStep.Init && (
        <TransactionChooseMethodView
          onSuccess={handleSuccess}
          title="¿Cuánto deseas retirar?"
          subtitle="Monto a retirar:"
          widgetInfo={widgetInfo}
        />
      )}
      {step === PayOutStep.WalletTransactionInit && (
        <WalletTransactionInitView
          onSuccess={handleSetStep(PayOutStep.WalletTransactionPreview)}
        />
      )}
      {step === PayOutStep.WalletTransactionPreview && (
        <TransactionPreviewView
          onSuccess={(newTransaction) => {
            setTransaction(newTransaction);
            setStep(PayOutStep.WalletTransactionSuccess);
          }}
          onError={(error) => {
            alert(error);
          }}
          widgetInfo={widgetInfo}
        />
      )}
      {step === PayOutStep.WalletTransactionSuccess && (
        <TransactionSuccessView
          onFinish={handleSetStep(PayOutStep.Init)}
          title="¡Tu retiro se realizó con éxito!"
          titleTable="Monto depositado:"
          transaction={transaction}
        />
      )}

      {/* VALIDATION TRANSactionT BANK */}
      {step === PayOutStep.BankTransactionInit && (
        <BankTransactionInitView
          onContinue={(bankTransactionRequest) => {
            setBankTransactionRequest(bankTransactionRequest);
            setStep(PayOutStep.BankTransactionPreview);
          }}
          onBack={handleSetStep(PayOutStep.Init)}
        />
      )}
      {step === PayOutStep.BankTransactionPreview && bankTransactionRequest && (
        <BankTransactionPreviewView
          widgetId={widgetInfo.widgetId}
          bankTransactionRequest={bankTransactionRequest}
          onSuccess={(newTransaction) => {
            setTransaction(newTransaction);
            setStep(PayOutStep.BankTransactionSuccess);
          }}
          onError={(message) => {
            setMessageError(message);
            setStep(PayOutStep.BankTransactionError);
          }}
        />
      )}
      {step === PayOutStep.BankTransactionSuccess && (
        <TransactionSuccessView
          onFinish={handleSetStep(PayOutStep.Init)}
          title="Tu retiro estará listo, en las siguientes 48 hrs , te avisaremos a tu mail"
          titleTable="Monto a retirar:"
          transaction={transaction}
        />
      )}
      {step === PayOutStep.BankTransactionError && (
        <TransactionErrorView
          textError={messageError}
          onFinish={handleSetStep(PayOutStep.Init)}
        />
      )}
    </>
  );
};

export default PayOut;
