import { Amplify } from "aws-amplify";

const awsConfig = {
  Auth: {
    mandatorySignIn: true,
    region: "us-east-1",
    userPoolId: "us-east-1_YtvLmD3kk",
    identityPoolId: "us-east-1:c3417506-8502-4633-a082-fda582f2e761",
    userPoolWebClientId: "31arf6ibpedd9b7cfl1alb57q7",
  },
};

export class AmplifyManager {
  static init = () => {
    Amplify.configure(awsConfig);
  };
}

// userPoolId: "us-east-1_YtvLmD3kk",
// identityPoolId: "us-east-1:c3417506-8502-4633-a082-fda582f2e761",
// userPoolWebClientId: "31arf6ibpedd9b7cfl1alb57q7"
