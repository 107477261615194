import React from "react";
import { HttpMethod, useFetch } from "../../../hooks/useFetch";
import { IBankTransactionRequestBody } from "../../../types";

export const useFetchTransactionPreview = () => {
  const [isFetching, setIsFetching] = React.useState(true);
  const [previewInfo, setPreviewInfo] = React.useState<any>();
  const [error, setError] = React.useState("");
  const { customFetch } = useFetch();

  const handleFetchTransactionPreview = async ({
    widgetId,
    bank,
    cci,
    accountNumber,
  }: IBankTransactionRequestBody) => {
    try {
      setIsFetching(true);
      const response = await customFetch(
        `widget/${widgetId}/preview`,
        HttpMethod.Post,
        {
          bank,
          cci,
          accountNumber,
          method: "PROMETEO",
        }
      );
      if (response.status === "SUCCESS") {
        setPreviewInfo(response.preview);
      } else if (response.status === "FAILURE") {
        setError(response.message);
      }
    } catch (error) {
      console.log("error", error);
      setError(error as string);
    } finally {
      setIsFetching(false);
    }
  };
  return {
    error,
    isFetching,
    previewInfo,
    onFetchTransactionPreview: handleFetchTransactionPreview,
  };
};
