import { Auth } from "aws-amplify";
import { useState } from "react";
import { IUser } from "../../../../types";

interface IRequestBody {
  username: string;
  password: string;
}

interface IParams {
  onSuccess: (user: IUser) => void;
  onError: () => void;
}

export const useLogin = ({ onSuccess, onError }: IParams) => {
  const [loading, setLoading] = useState(false);

  const handleLogin = async ({ username, password }: IRequestBody) => {
    try {
      setLoading(true);
      let cognitoUser = await Auth.signIn({
        username,
        password,
      });
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        try {
          await Auth.completeNewPassword(cognitoUser, password);
          cognitoUser = await Auth.signIn({
            username,
            password,
          });
        } catch (error) {
          console.log(error);
        }
      }
      onSuccess({
        id: cognitoUser?.attributes?.sub,
        name: cognitoUser?.attributes?.name,
        lastName: cognitoUser?.attributes?.family_name,
        email: cognitoUser?.attributes?.email,
      });
    } catch (error) {
      console.log("error", error);
      onError();
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    onLogin: handleLogin,
  };
};
