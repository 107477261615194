import React, { useState } from "react";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import styles from "./index.module.css";
import commonStyles from "../../common/common.module.css";
import TransactionMethodView from "../transactionMethodView";
import Hand from "../../../assets/hand.svg";
import { IWidgetInfo } from "../../../types";
import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
import { TransactionMethod } from "../../../constants";

interface IProps {
  onSuccess: (method: TransactionMethod) => void;
  title: string;
  subtitle: string;
  isPayIn?: boolean;
  widgetInfo: IWidgetInfo;
}

const TransactionChooseMethodView: React.FC<IProps> = ({
  onSuccess,
  title,
  subtitle,
  isPayIn,
  widgetInfo,
}) => {
  const [rechargeType, setRechargeType] = useState<TransactionMethod>();
  const [isCkeckEnabled, setIsCkeckEnabled] = useState(false);
  const [wasFormSubmitted, setWasFormSubmitted] = useState(false);

  const handleSetRechargeByBankTransfer = () => {
    setRechargeType(TransactionMethod.BankTransfer);
  };
  const handleSetRechargeByWallet = () => {
    setRechargeType(TransactionMethod.Wallet);
  };

  const isFormValid =
    (rechargeType === TransactionMethod.BankTransfer ||
      rechargeType === TransactionMethod.Wallet) &&
    isCkeckEnabled;

  const handleContinue = () => {
    setWasFormSubmitted(true);
    if (widgetInfo.configuration?.origin === "mobile") {
      onSuccess(TransactionMethod.BankTransfer);
    } else {
      onSuccess(rechargeType!);
    }
  };

  const onChangeEnabled = (values: CheckboxChangeEvent) => {
    setIsCkeckEnabled(values.target.checked);
  };
  const handleBack = () => {
    (window as any).parent.postMessage(
      {
        type: "CLOSE_MODAL",
      },
      "*"
    );
  }
  return (
    <section className={commonStyles.container}>
      <div style={{ marginBottom: "20px" }}>
        {widgetInfo.configuration?.origin !== "mobile" && (
          <p className={styles.title}>{title}</p>
        )}
      </div>
      <div>
        <Form layout="vertical">
          <Form.Item>
            <label className={commonStyles.labelForm}>{subtitle}</label>
            <Input
              className={
                widgetInfo.configuration?.origin !== "mobile"
                  ? styles.inputForm
                  : styles.inputFormMobile
              }
              value={`S/ ${widgetInfo.amount}`}
              disabled
            />
          </Form.Item>
          <Form.Item
            name="select"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <label className={commonStyles.labelForm}>
              Elige una forma de pago:
            </label>
            {widgetInfo.configuration?.transfer && (
              <TransactionMethodView
                text="Transferencias bancarias:"
                isWalletMaximo={false}
                onClick={handleSetRechargeByBankTransfer}
                isActive={
                  widgetInfo.configuration?.origin !== "mobile"
                    ? rechargeType === TransactionMethod.BankTransfer
                    : true
                }
              />
            )}
            <div style={{ height: "10px" }}></div>
            {widgetInfo.configuration?.maximo && (
              <TransactionMethodView
                text="Billetera:"
                isWalletMaximo={true}
                onClick={handleSetRechargeByWallet}
                isActive={rechargeType === TransactionMethod.Wallet}
              />
            )}
          </Form.Item>
          {isPayIn && (
            <p className={styles.fotterText}>
              <img src={Hand} alt="" style={{ marginRight: "20px" }} />
              El titular de la cuenta debe ser  el mismo que  el titular de la cuenta de origen.
            </p>
          )}

          <Form.Item>
            <Checkbox className={styles.check} onChange={onChangeEnabled}>
              <span>
                Acepto los
                <a
                  href="https://maximo.pe/terminos-y-condiciones"
                  target="_blank"
                  className={styles.url}
                  rel="noreferrer"
                >
                  términos y condiciones
                </a>
              </span>
            </Checkbox>
          </Form.Item>
          <Row align={"middle"}>
            <Col span={10} offset={2}>
              {widgetInfo.configuration?.origin === "mobile" && (
                <Button
                  className={styles.backButton}
                  onClick={handleBack}
                >
                  Volver
                </Button>
              )}

            </Col>
            <Col span={widgetInfo.configuration?.origin !== "mobile" ? 24 : 10}>
              <Button
                className={widgetInfo.configuration?.origin !== "mobile" ? commonStyles.button : styles.continueButton}
                onClick={handleContinue}
                disabled={
                  widgetInfo.configuration?.origin !== "mobile"
                    ? !isFormValid
                    : !isCkeckEnabled
                }
                loading={wasFormSubmitted}
              >
                Continuar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </section>
  );
};

export default TransactionChooseMethodView;
